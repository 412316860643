import { v4 as uuidV4 } from 'uuid';

// eslint-disable-next-line
export class Text {
	static RemoveDuplicateLines(input: string | undefined) {
		if (!input) {
			return '';
		}

		const lines = input.split('\n');
		const uniqueLines = Array.from(new Set(lines));
		return uniqueLines.join('\n');
	}

	static ReplaceOrAppendLine(content: string, searchFor: string, newLine: string) {
		const lines = (content ?? '').split('\n');
		const index = lines.findIndex((l) => l.startsWith(searchFor));

		if (index >= 0) {
			lines[index] = newLine;
		} else {
			lines.push(newLine);
		}

		return lines.join('\n');
	}

	static ExtractRegex(message: string, regex: RegExp | string, group = 1): string | undefined {
		const matches = message.match(regex);
		if (matches && matches.length > group) {
			return matches[group];
		}
		return undefined;
	}

	static ExtractRegexes(message: string, regex: RegExp | string, ...groups: number[]): string[] {
		const matches = message.match(regex);
		if (groups && matches && matches.length > Math.max(...groups)) {
			return groups.map((g) => matches[g]);
		}
		return [];
	}

	static ExecRegex(content: string, regex: RegExp | string, action: (t: RegExpExecArray) => void): void {
		const regexp = new RegExp(regex, 'g');
		let t = regexp.exec(content);
		while (t) {
			if (t?.length) {
				action(t);
			}
			t = regexp.exec(content);
		}
	}

	static TakeSummary(input: string, charLimit: number) {
		const EXTRA_LENGTH = 5;
		if (input.length > charLimit - EXTRA_LENGTH) {
			const halfLimit = Math.floor((charLimit - EXTRA_LENGTH) / 2);
			return `${Text.TakeFirstCharacters(input, halfLimit)}
...
${Text.TakeLastCharacters(input, halfLimit)}`;
		}
		return input;
	}

	static TakeLastCharacters(input: string, charLimit: number) {
		if (input.length > charLimit) {
			return input.substring(Math.max(0, input.length - charLimit));
		}
		return input;
	}

	static TakeFirstCharacters(input: string, charLimit: number, includeEllipsis = false) {
		if (input.length > charLimit) {
			return input.substring(0, charLimit) + (includeEllipsis ? '...' : '');
		}
		return input;
	}

	static RandomString(length = 20) {
		return [...Array<number>(length)].map(() => Math.random().toString(36)[2]).join('');
	}

	// eslint-disable-next-line
	static RandomUUID<T extends string>(): T {
		return uuidV4() as T;
	}

	static ExtractGeneral(
		message: string,
		start: string,
		end: string,
		shouldRemoveFirstLine: boolean,
		shouldCaptureTags: boolean,
		isGreedy: boolean,
	) {
		try {
			let firstBracketIndex = (message || '').indexOf(start);
			if (firstBracketIndex >= 0) {
				let lastBracketIndex = isGreedy
					? (message || '').lastIndexOf(end)
					: (message || '').indexOf(end, firstBracketIndex + start.length);
				if (lastBracketIndex > firstBracketIndex) {
					if (shouldCaptureTags) {
						lastBracketIndex += end.length;
					} else {
						firstBracketIndex += start.length;
					}

					let result = message?.substring(firstBracketIndex, lastBracketIndex);

					if (shouldRemoveFirstLine) {
						const newLineIndex = result.indexOf('\n');
						if (newLineIndex >= 0) {
							result = result.substring(newLineIndex + 1);
						}
					}

					return (result ?? '').trim();
				}
			}
		} catch (error) {
			console.error(error);
		}
		return undefined;
	}

	static ExtractHTMLTags(message: string, tag: string) {
		const matches: string[] = [];
		let unparsedData = message;
		let command = Text.ExtractHTMLTag(unparsedData, tag);
		while (command) {
			if (command) {
				matches.push(command);
			}
			unparsedData = unparsedData.substring(unparsedData.indexOf(command) + command.length);
			command = Text.ExtractHTMLTag(unparsedData, tag);
		}
		return matches;
	}

	static ExtractHTMLTag(message: string, tag: string) {
		return Text.ExtractGeneral(message, `<${tag}>`, `</${tag}>`, false, true, false);
	}

	static RemoveHTMLTags(message: string, tag: string) {
		let unparsedData = message;
		const matches = Text.ExtractHTMLTags(message, tag);
		if (matches?.length) {
			for (const match of matches) {
				unparsedData = unparsedData.replace(match, '').trim();
			}
		}
		return unparsedData;
	}

	static ExtractJSON(message: string): unknown {
		try {
			const matched = Text.ExtractGeneral(message, '{', '}', false, true, true);
			if (matched) {
				return JSON.parse(matched) as unknown;
			}
		} catch (error) {
			console.error(error);
		}
		return undefined;
	}

	static ExtractJSONArray<T>(message: string) {
		try {
			const matched = Text.ExtractGeneral(message, '[', ']', false, true, false);
			if (matched) {
				return JSON.parse(matched) as T[];
			}
		} catch (error) {
			console.error(error);
		}
		return [] as T[];
	}
}
