import type { ToolCallRequest } from '@/app/lib/dtos/ToolCallRequest';
import type { ToolCallResponse } from '@/app/lib/dtos/ToolCallResponse';
import type { MessageContentType } from '@/app/lib/entities/ChatMessage.entity';

// eslint-disable-next-line
export class Shared {
	static VALID_UI_MESSAGE_TYPES: MessageContentType[] = ['image', 'text', 'tool-request', 'tool-result', 'file'];

	static LimitToTheLast<T>(array: T[], limit: number): T[] {
		let output = array;

		if (output.length > limit) {
			output = output.slice(Math.max(0, output.length - limit));
		}

		return output;
	}

	static IsQuestionBody(body: unknown): boolean {
		const messageContent = Shared.ToString(body);
		const questionStart = messageContent.includes('\n-----\nQUESTION STARTS');
		const questionEnd = messageContent.includes('QUESTION ENDS\n-----');

		return questionStart && questionEnd;
	}

	static LimitByPredicate<T>(data: T[], limit: number, predicate: (arg0: T) => string | number | symbol): T[] {
		const output: T[] = [];
		const map: Record<string | number | symbol, number> = {};
		const len = data.length;
		for (let i = len - 1; i >= 0; i -= 1) {
			const t = data[i];
			const k = predicate(t);
			if (k) {
				if (!map[k]) {
					map[k] = 0;
				}
				if (map[k] < limit) {
					map[k] += 1;
					output.unshift(t);
				}
			}
		}

		return output;
	}

	static MergeArrays<T>(array1: T[], array2: T[], sameTest: (a: T, b: T) => boolean): T[] {
		const output: T[] = [];
		const exists = (el: T) => {
			return output.find((value: T) => sameTest(value, el)) != undefined;
		};

		for (const val1 of array1) {
			if (!exists(val1)) {
				output.push(val1);
			}
		}

		for (const val2 of array2) {
			if (!exists(val2)) {
				output.push(val2);
			}
		}

		return output;
	}

	static Wait(ms: number): Promise<void> {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	static IsString(candidate: unknown, errorMessage = 'candidate is not a string'): asserts candidate is string {
		if ((candidate ?? 0).constructor.name != 'String') {
			throw new Error(errorMessage);
		}
	}

	static IsArray<T>(candidate: unknown, errorMessage = 'candidate is not an Array'): asserts candidate is T[] {
		if ((candidate ?? 0).constructor.name != 'Array') {
			throw new Error(errorMessage);
		}
	}

	static ToString(candidate: unknown, defaultValue = ''): string {
		if (candidate == undefined) {
			return defaultValue;
		}

		if (typeof candidate == 'string') {
			return candidate;
		} else if (candidate?.constructor?.name == 'Date') {
			return (candidate as Date).toISOString();
		} else if (typeof candidate == 'object') {
			return JSON.stringify(candidate);
		}

		// eslint-disable-next-line
		return String(candidate);
	}

	static ToNumber(candidate: unknown, defaultValue = 0): number {
		if (candidate == undefined) {
			return defaultValue;
		}

		if (typeof candidate == 'number') {
			return candidate;
		} else if (typeof candidate == 'string' && !isNaN(Number(candidate))) {
			return Number(candidate);
		}

		return defaultValue;
	}

	static ToBoolean(candidate: unknown, defaultValue = false): boolean {
		if (candidate == undefined) {
			return defaultValue;
		}

		if (typeof candidate == 'boolean') {
			return candidate;
		} else if (typeof candidate == 'string') {
			return candidate.trim().toLocaleLowerCase() == 'true';
		}

		return defaultValue;
	}

	static IsToolCallRequest(
		candidate: unknown,
		errorMessage = 'candidate is not a ToolCallRequest',
	): asserts candidate is ToolCallRequest {
		if (!(candidate as ToolCallRequest).arguments) {
			throw new Error(errorMessage);
		}
	}

	static IsToolCallResponse(
		candidate: unknown,
		errorMessage = 'candidate is not a ToolCallResponse',
	): asserts candidate is ToolCallResponse {
		const response = (candidate as ToolCallResponse).response;
		if (!response && response !== '') {
			throw new Error(errorMessage);
		}
	}

	static IsValidEnvironmentVariableName(name: string) {
		return /^[a-zA-Z][a-zA-Z0-9_]*$/.test(name);
	}
}
