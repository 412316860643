'use client';

import type { Thread, ThreadId } from '@/app/lib/entities/Thread.entity';
import type { User } from '@/app/lib/entities/User.entity';
import { createContext, useContext } from 'react';

export interface SocketData {
	isConnected: boolean;
	user?: User | undefined;
	threads: Thread[];
	selectThreadId: (threadId: ThreadId | undefined, user: User | undefined) => void;
}

const baseSocketData: SocketData = {
	isConnected: false,
	threads: [],
	selectThreadId: () => {
		return;
	},
};

export const SocketContext = createContext<SocketData>(baseSocketData);

export function useSocketContext() {
	return useContext(SocketContext);
}
