import type { ToolType } from '@/app/lib/types/ToolType';
import type { User, UserType } from '@/app/lib/types/User';

// eslint-disable-next-line
export class Users {
	static Users: Record<UserType, User> = {
		user: {
			id: 'user',
			displayName: 'you',
		},
		projectManager: {
			id: 'projectManager',
			displayName: 'Natalya - Project Manager',
		},
		architect: {
			id: 'architect',
			displayName: 'Peter - Architect',
		},
		designer: {
			id: 'designer',
			displayName: 'Sara - Designer',
		},
		engineer: {
			id: 'engineer',
			displayName: 'Adam - Engineer',
		},
		tester: {
			id: 'tester',
			displayName: 'Noah - Tester',
		},
		devops: {
			id: 'devops',
			displayName: 'Emma - DevOps',
		},
	};

	static DetermineTargetUser(toolType: ToolType, defaultUser: UserType = 'projectManager'): UserType {
		if (toolType == 'ask-designer') {
			return 'designer';
		} else if (toolType == 'ask-engineer') {
			return 'engineer';
		} else if (toolType == 'ask-architect') {
			return 'architect';
		} else if (toolType == 'ask-devops') {
			return 'devops';
		} else if (toolType == 'ask-tester') {
			return 'tester';
		} else if (toolType == 'ask-project-manager') {
			return 'projectManager';
		}
		return defaultUser;
	}
}
