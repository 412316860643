'use client';

import { useSocketContext } from '@/app/socket-context';
import { redirect } from 'next/navigation';
import { useEffect } from 'react';

export const dynamic = 'force-dynamic';

export default function Page() {
	const socketData = useSocketContext();
	const { user } = socketData;
	const threadId = user?.selectedThreadId;

	useEffect(() => {
		if (threadId) {
			redirect(`/${threadId}`);
		}
	}, [threadId]);
}
